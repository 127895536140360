<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'

import { Swiper, SwiperSlide } from 'swiper/vue' // swiper 所需组件
import { Autoplay, Navigation, Pagination, A11y } from 'swiper' // 分页器
import 'swiper/swiper-bundle.css'

const store = useStore()
const modules = [Autoplay, Pagination, Navigation, A11y]
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))

const checkedtype = ref(langvalue.value.Bus_introduction.three_1)
const type = ref(langtype)
let activeIndex = ref(0)
const onSlideChange = e => {
  activeIndex.value = e.activeIndex
}
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).Bus_introduction.three_1
    type.value = newVal
  },
  { immediate: true }
)
</script>

<template>
  <div class="a_detail">
    <AboutBanner
      :checkedtype="checkedtype"
      title="Data Co-Construction Center"
    />
    <div class="center_box">
      <div class="top">
        <div class="title">
          <span class="line"></span>
          <span class="text">{{ langvalue.center.one }}</span>
          <span class="line"></span>
        </div>
      </div>
      <div class="detail">
        {{ langvalue.center.two }}
      </div>
    </div>
    <div class="center">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.center.three }}</span>
        <span class="line"></span>
      </div>
      <div class="c_cont flex">
        <div class="block_wrap">
          <div class="item">
            <div class="header">{{ langvalue.center.title }}</div>
            <div class="con_detail">
              <ul>
                <li>{{ langvalue.center.five }}</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="header">{{ langvalue.center.title_1 }}</div>
            <div class="con_detail">
              <ul>
                <li>{{ langvalue.center.seven }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="block_wrap">
          <div class="item">
            <div class="header">{{ langvalue.center.title_2 }}</div>
            <div class="con_detail">
              <ul>
                <li>{{ langvalue.center.nine }}</li>
                <li>{{ langvalue.center.nine_1 }}</li>
                <li>{{ langvalue.center.nine_2 }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.center.nine_3 }}</span>
        <span class="line"></span>
      </div>
      <div class="main_box">
        <div class="box_item">
          <div class="he_titl f">
            <p>{{ langvalue.center.district }}</p>
            <p>{{ langvalue.center.district_ti }}</p>
          </div>
          <div class="line f"></div>
          <div class="m_de f">
            {{ langvalue.center.content }}
            {{ langvalue.center.content_1 }}
          </div>
        </div>
        <div class="box_item">
          <div class="he_titl f">
            <p>{{ langvalue.center.district_1 }}</p>
            <p>{{ langvalue.center.district_ti }}</p>
          </div>
          <div class="line f"></div>
          <div class="m_de f" style="width: 223px;">
            <p>{{ langvalue.center.content_2 }}</p>
          </div>
        </div>
        <div class="box_item">
          <div class="he_titl">
            <p>{{ langvalue.center.district_2 }}</p>
            <p>{{ langvalue.center.district_ti }}</p>
          </div>
          <div class="line"></div>
          <div class="m_de" style="width: 223px;">
            <p>{{ langvalue.center.content_3 }}</p>
          </div>
        </div>
        <div class="box_item">
          <div class="he_titl">
            <p>{{ langvalue.center.district_3 }}</p>
            <p>{{ langvalue.center.district_ti }}</p>
          </div>
          <div class="line"></div>
          <div class="m_de" style="width: 223px;">
            <p>{{ langvalue.center.content_4 }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="center2">
      <img
        v-if="langvalue.center.img == '10'"
        class="bg_img"
        src="../../assets/images/yw_10.png"
        alt=""
      />
      <img
        v-else-if="langvalue.center.img == '11'"
        class="bg_img"
        src="../../assets/images/yw_11.png"
        alt=""
      />
      <img v-else class="bg_img" src="../../assets/images/yw_12.png" alt="" />
    </div>

    <div class="lun_center">
      <div class="top">
        <div class="title">
          <span class="line"></span>
          <span class="text">{{ langvalue.center.title_3 }}</span>
          <span class="line"></span>
        </div>
      </div>
      <div class="detail">
        <div class="main">
          <Swiper
            :slidesPerView="1"
            :spaceBetween="30"
            :loop="false"
            :centeredSlides="false"
            :pagination="{ clickable: true }"
            :autoplay="{ delay: 900, disableOnInteraction: false }"
            :navigation="false"
            :modules="modules"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <div class="box">
                <div class="right_main">
                  <div class="r_title">
                    <p>{{ langvalue.section4_3_2.title }}</p>
                    <p>{{ langvalue.section4_3_2.two }}</p>
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_1.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_2.three.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_2.three.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_2.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_2.four.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_2.four.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_3.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_2.five.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_2.five.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_4.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_2.six.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_2.six.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_5.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_2.seven.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_2.seven.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_6.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_2.eight.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_2.eight.split('：')[1] }}
                  </div>
                </div>
                <div class="left_main">
                  <img
                    src="https://resources.wukongbc.com/img/kuangchang/2.png"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="box">
                <div class="right_main">
                  <div class="r_title">
                    <p>{{ langvalue.section4_3_1.title }}</p>
                    <p>{{ langvalue.section4_3_1.two }}</p>
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_1.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_1.three.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_1.three.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_2.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_1.four.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_1.four.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_3.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_1.five.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_1.five.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_4.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_1.six.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_1.six.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_5.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_1.seven.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_1.seven.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_6.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_1.eight.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_1.eight.split('：')[1] }}
                  </div>
                </div>
                <div class="left_main">
                  <img
                    src="https://resources.wukongbc.com/img/kuangchang/4.png"
                  />>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="box">
                <div class="right_main">
                  <div class="r_title">
                    <p>{{ langvalue.section4_3_3.title }}</p>
                    <p>{{ langvalue.section4_3_3.two }}</p>
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_1.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_3.three.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_3.three.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_2.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_3.four.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_3.four.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_3.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_3.five.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_3.five.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_4.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_3.six.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_3.six.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_6.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_3.seven.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_3.seven.split('：')[1] }}
                  </div>
                </div>
                <div class="left_main">
                  <img
                    src="https://resources.wukongbc.com/img/kuangchang/6.png"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="box">
                <div class="right_main">
                  <div class="r_title">
                    <p>{{ langvalue.section4_3_4.title }}</p>
                    <p>{{ langvalue.section4_3_4.two }}</p>
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_1.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_4.three.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_4.three.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_2.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_4.four.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_4.four.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_3.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_4.five.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_4.five.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_4.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_4.six.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_4.six.split('：')[1] }}
                  </div>
                  <div class="mainitem">
                    <img class="ricon" src="../../assets/images/anli_6.svg" />
                    <span class="label">
                      {{ langvalue.section4_3_4.seven.split('：')[0] }}：</span
                    >
                    {{ langvalue.section4_3_4.seven.split('：')[1] }}
                  </div>
                </div>
                <div class="left_main">
                  <img
                    src="https://resources.wukongbc.com/img/kuangchang/7.png"
                  />
                </div>
              </div>
            </swiper-slide>
          </Swiper>
          <div class="indicator">
            <div :class="['item', activeIndex == 0 ? 'active' : '']"></div>
            <div :class="['item', activeIndex == 1 ? 'active' : '']"></div>
            <div :class="['item', activeIndex == 2 ? 'active' : '']"></div>
            <div :class="['item', activeIndex == 3 ? 'active' : '']"></div>
          </div>

          <!-- <div class="more_box" v-if="moreShow">
          <div class="more" @click="goAboutInfo">
            <span>{{ langvalue.other.six }}</span>
            <img src="../../assets/images/arrow-righanli_1.svg" alt="" />
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <div class="poin_box">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.center.title_4 }}</span>
        <span class="line"></span>
      </div>
      <div class="main_deti">
        <div class="item">
          <img style="margin-right: 88px;" src="../../assets/images/dqu_1.png" alt="" />
          <div class="right">
            <div class="title_">
              {{ langvalue.center.district_2}}
              {{langvalue.center.district_ti }}
            </div>
            <div class="detils_">
              <div class="s_title">{{ langvalue.center.title_5 }}</div>
              <ul>
                <li>{{ langvalue.center.content_5 }};</li>
                <li>{{ langvalue.center.content_6 }};</li>
                <li>{{ langvalue.center.content_7 }};</li>
                <li>{{ langvalue.center.content_8 }}</li>
              </ul>
              <div class="s_title">{{ langvalue.center.title_6 }}</div>
              <ul>
                <li>{{ langvalue.center.content_9 }}</li>
                <li>{{ langvalue.center.content_10 }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="item" style="margin-top: 240px;">
          <div class="right">
            <div class="title_" style="text-align: right;">
              {{ langvalue.center.district_1}}
              {{langvalue.center.district_ti }}
            </div>
            <div class="detils_ els">
              <ul>
                <li>{{ langvalue.center.content_11 }}</li>
                <li>{{ langvalue.center.content_12 }}</li>
                <li>{{ langvalue.center.content_13 }}</li>
              </ul>
            </div>
          </div>
          <img style="margin-left: 88px;" src="../../assets/images/dqu_2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.flex {
  display: flex;
}
.a_detail {
  width: 100%;

  .center_box {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background: #eaebf0;

    .top {
      padding: 144px 0 0;
    }
    .detail {
      color: #100e34;
      text-align: center;
      padding: 0 240px 147px;
      margin-top: 99px;
      font-size: 24px;
      font-style: normal;
      line-height: 180%; /* 43.2px */
      letter-spacing: 2px;
      text-transform: capitalize;
    }
  }
.center2 {
    box-sizing: border-box;
    background: #EAEBF0;
    display: flex;
    justify-content: center;
    .bg_img {
      // border: 1px solid red;
      width: 1240px;
      margin: 0 auto;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 400;
    color: #100e34;
    font-family: 'PingFang SC';

    display: flex;
    justify-content: center;
    align-items: center;
    .line {
      display: inline-block;
      width: 100px;
      height: 2px;
      background: #d3d5e0;
    }
    .text {
      margin: 0 20px;
    }
  }

  .center {
    box-sizing: border-box;
    width: 1540px;
    margin: 0 auto;
    padding: 135px 100px 184px;
    background: #f9f9f9;

    .c_cont {
      justify-content: space-between;
      margin: 105px auto 212px;
      .block_wrap {
        width: 540px;

        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          flex: 1;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 10px 60px 0px rgba(35, 35, 35, 0.1);
          overflow: hidden;
          padding-bottom: 60px;

          .header {
            height: 70px;
            line-height: 70px;
            text-align: center;
            background: linear-gradient(180deg, #0186ff 0%, #01b1ff 100%);
            color: #fff;
            font-family: Poppins;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
          }
          .con_detail {
            padding: 25px 50px;
            color: #333;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            ul {
              list-style: disc;
              li + li {
                margin-top: 33px;
              }
            }
          }
        }

        .item + .item {
          margin-top: 60px;
        }
      }
      .block_wrap + .block_wrap {
        margin-left: 52px;
      }
    }
    .main_box {
      margin-top: 96px;
      display: flex;
      justify-content: space-between;
      .box_item {
        padding: 106px 0 0 65px;
        min-height: 580px;
        // width: 482px;
        box-sizing: border-box;
        flex: 1;
        .he_titl {
          font-family: 'PingFang SC';
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 2px;
          color: #100e34;
          // text-transform: capitalize;
          &.f {
            color: #fff;
          }
        }
        .line {
          width: 64px;
          height: 2px;
          background: #100e34;
          margin-top: 83px;
          &.f {
            background: #fff;
          }
        }

        .m_de {
          margin-top: 18px;
          width: 210px;
          &.f {
            color: #fff;
          }
        }
      }
      .box_item:nth-child(1) {
        background: url('../../assets/images/yw_1.png') no-repeat;
        background-size: 100% 100%;
      }
      .box_item:nth-child(2) {
        background: url('../../assets/images/yw_2.png') no-repeat;
        background-size: 100% 100%;
      }
      .box_item:nth-child(3) {
        background: url('../../assets/images/yw_3.png') no-repeat;
        background-size: 100% 100%;
      }
      .box_item:nth-child(4) {
        background: url('../../assets/images/yw_4.png') no-repeat;
        background-size: 100% 100%;
      }
      .box_item + .box_item {
        margin-left: -24px;
      }
    }
  }

  .lun_center {
    background: #fff;
    box-sizing: border-box;
    width: 1540px;
    margin: 0 auto;
    padding: 130px 100px 170px;

    .detail {
      width: 1340px;
      font-size: 20px;
      font-weight: 300;
      margin: 93px auto 0;
      display: flex;

      .main {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        margin: 2px auto;
        // background: white;
        border-radius: 32px;
        // position: relative;
        .box {
          border-radius: 15px;
          background: linear-gradient(270deg, #fff 5.28%, #f3f3f4 97.85%);
          display: flex;
          .right_main {
            padding: 79px 49px 46px;

            .r_title {
              color: #586477;
              font-family: 'PingFang SC';
              font-size: 40px;
              font-style: normal;
              font-weight: 600;
              line-height: 80px; /* 200% */
              margin-bottom: 67px;
              width: 593px;
              text-wrap: wrap;
            }

            .mainitem {
              color: #666;
              font-size: 22px;
              font-weight: 400;
              display: flex;
              align-items: center;
              width: 593px;
              text-wrap: wrap;

              .ricon {
                width: 30px;
                height: 30px;
                margin-right: 35px;
              }

              .label {
                color: #111;
                font-weight: 500;
              }
            }
            .mainitem + .mainitem {
              margin-top: 28px;
            }
          }
          .left_main {
            img {
              height: 100%;
              border-radius: 0 15px 15px 0;
            }
          }
        }

        .indicator {
          margin-top: 60px;
          display: flex;
          justify-content: center;

          .item {
            width: 18px;
            height: 18px;
            background: #cbcbcb;
            border-radius: 50%;

            &.active {
              background: #000;
            }
          }

          .item + .item {
            margin-left: 20px;
          }
        }

        .main_title {
          font-size: 35px;
          color: #586477;
          width: 600px;
          text-wrap: wrap;
        }

        .img {
          margin-top: 86px;

          width: 1008px;
          height: 594px;

          img {
            border-radius: 15px;
          }
        }

        // .left {
        //   background: #fff;
        //   top: -36px;
        //   right: 0;
        //   // width: 779px;
        //   // height: 658px;
        //   border-radius: 24px 0 0 24px;
        //   box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.04);
        // }

        .more_box {
          display: flex;
          justify-content: flex-end;
          margin-top: 80px;

          .more {
            vertical-align: middle;
            padding: 12px 0;
            border-bottom: 1px solid #000;

            span {
              color: #000;
              font-weight: 600;
              font-size: 20px;
            }

            img {
              width: 24px;
              height: 24px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .poin_box {
    box-sizing: border-box;
    width: 1540px;
    margin: 0 auto;
    padding: 156px 100px 170px;
    background: #eaebf0;

    .main_deti {
      margin-top: 168px;
      .item {
        display: flex;

        .right {
          .title_ {
            color: #586477;
            font-family: 'PingFang SC';
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: 90px; /* 180% */
          }

          .detils_ {
            .s_title {
              color: #586477;
              font-family: 'PingFang SC';
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: 90px; /* 257.143% */
            }

            ul {
              list-style: disc;
              padding-left: 23px;
              li {
                color: #586477;
                font-family: 'PingFang SC';
                font-size: 22px;
                font-weight: 400;
                line-height: 40px; /* 181.818% */
              }
            }
            &.els {
              ul {
                margin-top: 40px;
                li +li {
                  margin-top: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
